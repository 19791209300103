import request from '@/utils/request'

const property_project_item_Api = {
  property_project_item_list: '/property_project/item/',
  property_project_item_create: '/property_project/item/',
  property_project_item_update: '/property_project/item/',
  property_project_item_delete: '/property_project/item/',
  property_project_item_payable: '/property_project/item/',
  property_project_item_pay_voucher: '/property_project/item/'
}

/**
 * 列表
 */
export function property_project_item_list (parameter) {
  return request({
    url: property_project_item_Api.property_project_item_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function property_project_item_create (parameter) {
  return request({
    url: property_project_item_Api.property_project_item_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function property_project_item_update (property_project_cell_id) {
  return request({
    url: property_project_item_Api.property_project_item_update + property_project_cell_id + '/',
    method: 'patch',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function property_project_item_delete (property_project_cell_id) {
  return request({
    url: property_project_item_Api.property_project_item_delete + property_project_cell_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 添加
 */
export function property_project_item_payable (property_project_cell_id) {
  return request({
    url: property_project_item_Api.property_project_item_payable + property_project_cell_id + '/payable/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 添加
 */
export function property_project_item_pay_voucher (property_project_cell_id) {
  return request({
    url: property_project_item_Api.property_project_item_pay_voucher + property_project_cell_id + '/voucher/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 添加
 */
export function property_project_item_pay_voucher_list (parameter) {
  return request({
    url: property_project_item_Api.property_project_item_pay_voucher + 'voucher/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
